import React from 'react';

interface Step {
  title: string;
  description: string;
}

interface ThreeStepsSectionProps {
  lead: string;
  title: string;
  steps: Step[];
}

const ThreeStepsSection: React.FC<ThreeStepsSectionProps> = ({
  lead,
  title,
  steps,
}) => {
  return (
    <div className="container max-w-screen-2lg mx-auto my-20 md:my-32 md:px-32 lg:px-52 xl:px-64 text-dark-grey">
      <div className="px-6 text-center">
        <div className="text-lg font-semibold text-center mb-1.5 text-mid-grey opacity-60">
          {lead}
        </div>
        <h2 className="mb-12 u-h2 text-center md:mb-20 lg:mb-24">
          {title}
        </h2>
      </div>

      <div className="px-6 mx-auto max-w-2xl">
        {steps.map((step, index) => (
          <div
            key={index}
            className="flex items-start md:items-center justify-between mb-20 last:mb-0"
          >
            <div className="md:text-center font-semibold text-primary-light text-6xl md:text-7.5 w-32 md:w-56 mt-2 md:mt-0">
              {index + 1}
            </div>
            <div className="">
              <h3 className="u-h3 mb-2.5">
                {step.title}
              </h3>
              <p className="text-lg">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThreeStepsSection;
