import { Link } from 'gatsby';
import React from 'react';
import CtaButton from 'components/atoms/CtaButton';

interface Product {
  name: string;
  description: string;
  price: string;
  cta: {
    label: string;
    url: string;
  };
}

interface PricingSectionProps {
  title: string;
  body: string;
  product1: Product;
  product2: Product;
  cta: { label: string; url: string };
  secondaryCta: { label: string; url: string };
}

const PricingSection: React.FC<PricingSectionProps> = ({ title, body, product1, product2, cta, secondaryCta }) => {
  return (
    <div className="bg-tile">
      <div className="container max-w-screen-xl py-20 md:py-32 mx-auto">
        <div className="px-6 mx-auto text-center md:px-32 lg:px-60 text-dark-grey mb-14">
          <h2 className="mb-3.5 u-h2">
            {title}
          </h2>
          <p className="text-lg md:px-12 lg:px-32 lg:text-xl">
            {body}
          </p>
        </div>

        <div className="flex flex-col px-6 mb-16 text-center md:flex-row md:px-24 lg:px-48 xl:px-64">
          <div className="w-full pt-12 px-12 pb-16 border-t-8 border-violet-alt mr-1.5 bg-white mb-8 md:mb-0">
            <div className="flex items-end justify-center md:h-20">
              <h3 className="mb-4 text-3xl font-bold tracking-tight lg:text-4xl">
                {product1.name}
              </h3>
            </div>
            <p className="mb-10">
              {product1.description}
            </p>
            <div className="mb-2 text-4xl font-bold">$20</div>
            <div className="mb-8">Per store / month</div>
            <Link className="u-cta-link" to={product1.cta.url}>
              {product1.cta.label}
            </Link>
          </div>
          <div className="w-full pt-12 px-12 pb-16 border-t-8 border-aqua mr-1.5 bg-white">
            <div className="flex items-end md:h-20">
              <h3 className="mb-4 text-3xl font-bold tracking-tight lg:text-4xl mx-auto">
                {product2.name}
              </h3>
            </div>
            <p className="mb-10">
              {product2.description}
            </p>
            <div className="mb-2 text-4xl font-bold">{product2.price}</div>
            <div className="mb-8">Per store / month</div>
            <Link className="u-cta-link" to={product2.cta.url}>
              {product2.cta.label}
            </Link>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center md:flex-row">
          <div className="mb-8 md:mr-5 md:mb-0">
            <CtaButton to={cta.url} label={cta.label} inverted />
          </div>

          <Link className="u-cta-link md:ml-8" to={secondaryCta.url}>
            {secondaryCta.label}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
