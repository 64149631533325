import React from 'react';

interface GetConnectedSectionProps {
  title: string;
  body: string;
  logos: string[];
}

const GetConnectedSection: React.FC<GetConnectedSectionProps> = ({
  title,
  body,
  logos,
}) => {
  return (
    <div className="my-20 md:my-32 text-dark-grey">
      <div className="container max-w-screen-xl mx-auto text-center md:text-left">
        <div className="px-6 xl:px-0 md:w-9/12 lg:w-6/12">
          <h2 className="mb-5 u-h2 max-w-xl">{title}</h2>
          <p className="mb-10 lg:mb-20 text-lg lg:text-xl">{body}</p>
          <div className="flex flex-wrap items-center justify-between lg:justify-start lg:space-x-16 mb-16">
            {logos.map((logo, index) => (
              <div
                key={index}
                className="w-6/12 lg:w-auto lg:flex-1 mb-10 lg:mb-0 flex justify-center md:justify-start"
              >
                <img src={logo} alt="" className="w-auto h-5" />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        className="overflow-visible overflow-x-hidden text-right md:bg-right bg-no-repeat bg-left h-80 lg:h-hero xl:h-hero-mobile"
        style={{
          backgroundImage: 'url(/images/skupos-pos-devices.jpg)',
          backgroundSize: 'auto 100%',
          backgroundPosition: 'center right',
        }}
      />
    </div>
  );
};

export default GetConnectedSection;
