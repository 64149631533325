import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import Benefit from 'components/molecules/Benefit';
import Feature from 'components/molecules/Feature';
import BenefitsSection from 'components/organisms/BenefitsSection';
import Hero from 'components/organisms/Hero';
import CtaSection from 'components/organisms/CtaSection';
import FeaturesSection from 'components/organisms/FeaturesSection';
import GetConnectedSection from 'components/organisms/GetConnectedSection';
import PricingSection from 'components/organisms/PricingSection';
import TaglineSection from 'components/organisms/TaglineSection';
import TestimonialSection from 'components/organisms/TestimonialSection';
import ThreeStepsSection from 'components/organisms/ThreeStepsSection';
import { graphql } from 'gatsby';
import getImageData from 'helpers/nf-image-data';

const heroImageData = getImageData('/images/fluid/convenience-retail-hero.jpg');

const leadColors = ['text-aqua', 'text-violet'];

const ConvenienceRetailContent = ({
  hero,
  features,
  tagline,
  benefits,
  testimonial,
  simpleSteps,
  getConnected,
  pricing,
  cta,
}) => {
  return (
    <>
      <Hero
        breadcrumb="Convenience Retail"
        heading={hero?.title}
        content={hero?.description}
        imageData={heroImageData}
        calloutSceneWidths="w-full md:w-8/12 xl:w-7/12"
        mask={true}
        button={{
          to: hero?.primaryCta?.url,
          label: hero?.primaryCta?.label,
        }}
        cards={[
          {
            heading: '$1.43 Reimbursed',
            content: 'Scan Data',
            color: 'aqua',
            y: '32%',
            x: '8%',
            mobileY: '23%',
            mobileX: '24%',
          },
          {
            heading: '2FOR1 Promotion',
            content: 'Engage',
            color: 'orange',
            y: '32%',
            x: '8%',
            mobileY: '23%',
            mobileX: '24%',
          },
        ]}
      />

      <FeaturesSection title={features?.title}>
        {features?.features?.map(({ title, body, image }, index) => (
          <Feature key={index} title={title} body={body} image={image} />
        ))}
      </FeaturesSection>

      <TaglineSection title={tagline?.title} cta={tagline?.cta} />

      <BenefitsSection title={benefits?.title} constrainTitle>
        {benefits?.benefits?.map(
          ({ title, body, lead, image, imageSide, cta }, index) => (
            <Benefit
              key={index}
              lead={lead}
              leadColor={leadColors[index % 2] as any}
              title={title}
              body={body}
              cta={{ to: cta.url, label: cta.label }}
              image={image}
              imageSide={imageSide}
              centered
            />
          ),
        )}
      </BenefitsSection>

      <TestimonialSection
        quote={testimonial?.quote}
        author={testimonial?.author}
        company={testimonial?.company}
        ctaLabel="View customer stories"
        className="bg-dark-grey"
        inverted
      />

      <ThreeStepsSection
        lead={simpleSteps?.lead}
        title={simpleSteps?.title}
        steps={simpleSteps?.steps}
      />

      <div className="border-b border-border-grey" />

      <GetConnectedSection
        title={getConnected?.title}
        body={getConnected?.body}
        logos={getConnected?.logos}
      />

      <PricingSection
        title={pricing?.title}
        body={pricing?.body}
        product1={pricing?.products[0]}
        product2={pricing?.products[1]}
        cta={pricing?.cta}
        secondaryCta={pricing?.secondaryCta}
      />

      <CtaSection headline={cta?.headline} />
    </>
  );
};

export const ConvenienceRetailPreview = ({ entry }) => {
  return <ConvenienceRetailContent {...entry.get('data').toJS()} />;
};

const ConvenienceRetailPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <ConvenienceRetailContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ConvenienceRetailPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/convenience-retail/index/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
          primaryCta {
            label
            url
          }
        }
        features {
          title
          features {
            title
            body
            image
          }
        }
        tagline {
          title
          cta {
            label
            url
          }
        }
        benefits {
          title
          benefits {
            lead
            title
            body
            cta {
              label
              url
            }
            image
            imageSide
          }
        }
        testimonial {
          quote
          author
          company
        }
        simpleSteps {
          title
          lead
          steps {
            title
            description
          }
        }
        getConnected {
          title
          body
          logos
        }
        pricing {
          title
          body
          products {
            name
            description
            price
            cta {
              label
              url
            }
          }
          cta {
            label
            url
          }
          secondaryCta {
            label
            url
          }
        }
        cta {
          headline
        }
      }
    }
  }
`;

export default ConvenienceRetailPage;
